<template>
    <div>
        <v-layout wrap justify-center>
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#283e51"
                spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
            <v-flex xs11 pt-8 text-left>
                <span style="font-size: 25px;font-weight: bolder;">CANCELLED BOOKING </span>
            </v-flex>
        </v-layout>
        <v-layout v-for="(item, i) in list" :key="i" wrap justify-center>
            <v-flex xs12 md11 pt-4>
                <v-card outlined class="pa-3">
                    <v-layout wrap justify-center>
                        <v-flex xs12>
                            <v-layout wrap pa-2>
                                <v-flex xs4 lg2 pl-2>
                                    <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                                </v-flex>
                                <v-flex xs8 lg10 pl-4>
                                    <v-layout wrap justify-start>
                                        <v-flex xs12 text-left pt-4 class="mainfont">

                                            <v-layout wrap justify-start>
                                                <v-flex xs3>
                                                    <span style="font-size: 17px;font-weight: bold;">
                                                        IB Name:

                                                    </span>
                                                    <span>
                                                        {{ item.accid.accName }}
                                                    </span>
                                                </v-flex>
                                                <v-flex xs3>
                                                    <span style="font-size: 17px;font-weight: bold;">
                                                        Range:

                                                    </span>
                                                    <span>
                                                        {{ item.accid.rangeid.officeName }}
                                                    </span>
                                                </v-flex>
                                            </v-layout>

                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Reserved
                                                    For&nbsp;:&nbsp;</span>
                                                <span class="bold-heading">{{ item.guestDetail.guestName }}</span>
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Phone</span>
                                                : <span class="bold-heading">{{ item.guestDetail.guestMobileNo }}</span>
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Required
                                                    Rooms&nbsp;:&nbsp;</span>
                                                {{ item.reqRooms }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left py-2>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Booked
                                                    Date&nbsp;:&nbsp;</span>

                                                {{ formatDate(item.selectedDate) }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Referred By&nbsp;: </span>
                                                {{ item.guestDetail.referredBy }}
                                            </span>
                                        </v-flex>
                                        <v-flex xs12 md3 text-left>
                                            <span class="mainfont" style="font-size: 17px">
                                                <span style="font-size: 17px;font-weight: bold;"> Accompanying
                                                    Persons&nbsp;: </span>

                                                {{ item.guestDetail.personAccompanyNo }}
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-start>
                                        <v-spacer></v-spacer>
                                        <v-flex xs6 lg2 align-self-end text-right>
                                            <v-btn plain class="text-capitalize"
                                                @click="getInfo(item._id), (viewdialog = true)" color="#3CB371">
                                                View more
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 v-if="list.length>0">
                <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                    v-model="currentPage" color="#283e51"></v-pagination>
            </v-flex>
        </v-layout>
        <v-dialog v-model="viewdialog" :max-width="$vuetify.breakpoint.name == 'lg' ||
            $vuetify.breakpoint.name == 'xl'
            ? '75%'
            : '90%'
            ">
            <v-card>
                <v-card-title style="font-family:poppinsregular;font-size:13px">
                    <v-layout wrap justify-center>
                        <v-flex xs4 lg2 align-self-start pt-8>
                            <v-img height="100%" contain src="../../assets/Images/ibimage12.jpg"></v-img>
                        </v-flex>
                        <v-flex xs10 pl-4>
                            <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                                <v-flex xs12>
                                    <v-card style="border-radius: 0px;" elevation="0">
                                        <v-layout wrap>
                                            <v-flex xs4 pl-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Guest Name<br /></span> {{
                                                    info.guestDetail.guestName }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 v-if="info.reqRooms" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Required Rooms<br /></span>
                                                {{ info.reqRooms
                                                }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Mobile Number<br /></span>
                                                {{
                                                    info.guestDetail.guestMobileNo }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Email Address<br /></span>
                                                {{
                                                    info.guestDetail.guestEmailId }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Accompanying
                                                    Persons<br /></span> {{
                                                        info.guestDetail.personAccompanyNo }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Referred By<br /></span> {{
                                                    info.guestDetail.referredBy }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 text-left v-if="info.guestDetail">
                                                <span style="font-weight: bold; color: #3CB371;"> Vehicle Numbers</span>
                                                <span v-for="(vehicleNumber, index) in info.guestDetail.vehicleNumbers"
                                                    :key="index"><br />
                                                    {{ vehicleNumber }}

                                                </span>

                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Other Details<br /></span>
                                                {{
                                                    info.guestDetail.anyOtherDetails }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Meals Type<br /></span> {{
                                                    info.guestDetail.mealsType }}
                                            </v-flex>
                                            <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">No. of
                                                    Vehicles<br /></span> {{
                                                        info.guestDetail.noOfVehicles }}
                                            </v-flex>
                                            <v-flex xs6 pl-3 pt-3 v-if="info.guestDetail" text-left>
                                                <span style="font-weight: bold; color: #3CB371;">Referred Person Phone
                                                    Number<br /></span> {{
                                                        info.guestDetail.referencePersonPh }}
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="viewdialog = false">close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {


    data() {
        return {
            itemArray2: ["Vegeterian", "Non-vegetarian"],
            // cdate: moment().format("YYYY-MM-DD"),
            curIdToDelete: null,
            name: null,
            editdialog: false,
            barlist: [],
            edit: [],
            userProfileTabs: null,
            subtab: null,
            appLoading: false,
            page: 1,
            keyArray: [],
            pages: 0,
            dialog1: false,
            preview: null,
            tab: null,
            barlist1: [],
            dialog3: false,
            deletedialog: false,
            minAmount: null,
            password: null,
            categoryarray: [],
            list: [],
            flag: false,
            flag2: false,
            auctionbased: [],
            currentpage: 1,
            menu1: false,
            cdate: null,
            menu2: false,
            menu3: false,
            menu4: false,
            fromDate: null,
            toDate: null,
            fromDate2: null,
            toDate2: null,
            msg: null,
            currentPage: 1,
            showSnackBar: false,
            info: [],
            dialog2: false,
            viewdialog: false,
        };
    },

    mounted() {
        this.getList();
    },
    watch: {
        currentPage() {
            this.getList();
        },
    },
    computed: {
        minDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formattedDate() {
            const date = new Date(this.edit.selectedDate);
            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
    },
    methods: {
        getInfo(id) {
            axios({
                method: "POST",
                url: "/single/booking",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    status: "Cancelled",
                    bookingid: id,
                },
            })
                .then((response) => {
                    this.info = response.data.data;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        formatDate(item) {
            var dt = new Date(item);
            var year = dt.getFullYear();
            dt = dt.toString();
            var strTime = dt.slice(4, 10) + " " + year;
            return strTime;
        },
        getList() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/my/bookings",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    status: "Cancelled",
                    categoryId: this.categoryId,
                    page: this.currentPage,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    limit: 10,
                },
            })
                .then((response) => {
                    this.list = response.data.data.map((item) => {
                        const date = new Date(item.selectedDate);
                        item.selectedDate = date.toLocaleDateString();
                        item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
                        return item;
                    });
                    this.totalData = response.data.totalLength;
                    this.selectedDatesArray = this.list.map((item) => item.selectedDate);
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
    
  